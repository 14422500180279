import { QueryClient, useMutation, useQueryClient } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { EditableOrganizationData } from "./OrganizationSettings";

const baseUrl = "/users/me/organizations";

export function useUpdateCurrentOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: EditableOrganizationData) => {
      const { data } = await axiosClient.put<EditableOrganizationData>(
        `${baseUrl}/${organization.name}`,
        organization
      );
      return data;
    },
    onSettled: () => invalidateOrganizationParams(queryClient),
  });
}

function invalidateOrganizationParams(queryClient: QueryClient) {
  queryClient.invalidateQueries("currentOrganization");
  queryClient.invalidateQueries("organizations");
}
