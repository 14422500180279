import { useEffect, useState, ComponentPropsWithoutRef } from "react";
import {
  mkCreateUserAction,
  mkDeleteUserAction,
  mkSetUserRolesAction,
} from "../../../actions/admin";
import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  ConfirmationModal,
  IconCheck24Px,
  Input,
  Modal,
  TabProps,
  Tabs,
  Text,
} from "@tocoman/ui";
import { User } from "ts-bindings/User";
import { UserRoleAssignment } from "ts-bindings/UserRoleAssignment";
import { useOnEq } from "../../../hooks/useOnEq";
import { MainUserRoles } from "./MainUserRoles";

import {
  Controller,
  FormProvider,
  RegisterOptions,
  useForm,
} from "react-hook-form";
import { PermissionTypeContainers } from "./PermissionTypeContainers";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { useFormDataMapper } from "./useFormDataMapper";
import { useUserFormDefaultValues } from "./useUserFormDefaultValues";
import { LicenseFeatureContainer } from "../../../LicenseFeatures/LicenseFeatureContainer";
import {
  LicenseFeature,
  useLicenseFeatureEnabled,
} from "../../../LicenseFeatures/useLicenseFeature";
import { useFeatureEnabled } from "src/client-ts/split/useFeatureEnabled";
import { FeatureFlags } from "src/client-ts/split/FeatureFlags";
import { useCreateUser, useInviteUserToSSO } from "./useUser";
import { CreateUserParams } from "ts-bindings/CreateUserParams";

type PropTypes = {
  isOpen: boolean;
  user: User | null;
  closeFn: () => void;
} & ComponentPropsWithoutRef<"div">;

export type AppPermission = {
  appPermission: boolean;
  readAll: boolean;
  writeAll: boolean;
  project: ProjectPermission[];
  projectGroup: ProjectGroupPermission[];
};

export type UserRolesData = {
  newUserEmail?: string;
  userId: string;
  mainUser: boolean;
  hasSSO?: boolean;
  sendInvitation?: boolean;
} & UserRolePermissions;

export type UserRolePermissions = {
  allPermissions: boolean;
  costControl: AppPermission;
  estimation: AppPermission;
  createProjectsPermission: boolean;
  costClassTargetEdit: boolean;
};

export interface ProjectGroupPermission {
  id: string;
  name: string;
  readPermission: boolean;
  writePermission: boolean;
}
export type ProjectPermission = {
  readPermission: boolean;
  writePermission: boolean;
} & EstimationProjectSummary;

export type TabIds = "costControl" | "estimation";

// Values used for new users in PRO organizations
const createProUserRolesData = (
  estimation: boolean,
  readAllCe: boolean,
  writeAllCe: boolean
) => ({
  userId: "",
  allPermissions: false,
  costControl: {
    appPermission: false,
    readAll: false,
    writeAll: false,
    project: [],
    projectGroup: [],
  },
  estimation: {
    appPermission: estimation,
    readAll: readAllCe,
    writeAll: writeAllCe,
    project: [],
    projectGroup: [],
  },
  createProjectsPermission: writeAllCe,
  costClassTargetEdit: false,
  mainUser: writeAllCe,
});

export const EditUserRolesModal = ({ isOpen, user, closeFn }: PropTypes) => {
  const { t } = useTranslation("admin", { keyPrefix: "users.edit" });

  const rolesEnabled = useLicenseFeatureEnabled(LicenseFeature.USER_ROLES);

  const [selectedTab, setSelectedTab] = useState<TabIds>(
    rolesEnabled ? "costControl" : "estimation"
  );
  const tabs: TabProps<TabIds>[] = [
    { id: "costControl", color: "components", label: t`costControlTab` },
    {
      id: "estimation",
      color: "components",
      label: t`estimationTab`,
    },
  ];

  const newUserAddBackendEnabled = useFeatureEnabled(
    FeatureFlags.NEW_ADD_USER_BACKEND
  );
  const ssoInviteEnabled = useFeatureEnabled(FeatureFlags.SSO_INVITE);

  const organizationInfo = useWorkerState("AdminOrganizationInfoState");
  const organization = organizationInfo?.organization;

  const createUserNew = useCreateUser();
  const inviteUserToSSO = useInviteUserToSSO();

  const methods = useForm<UserRolesData>();
  const { handleSubmit, reset, register, watch, control } = methods;

  const emailFieldValue = watch("newUserEmail");
  const SSOValue = watch("hasSSO");

  const mkInputProps = (
    field: keyof UserRolesData,
    registerOptions?: RegisterOptions<UserRolesData, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const { mapFormToRoleAssignments } = useFormDataMapper();

  const onSubmit = (data: UserRolesData) => {
    if (organization) {
      const roleData = rolesEnabled
        ? data
        : createProUserRolesData(
            data.estimation.appPermission,
            data.estimation.readAll,
            data.estimation.writeAll
          );
      if (!data.newUserEmail) {
        updateUser(
          data.userId,
          mapFormToRoleAssignments(roleData),
          organization
        );
      } else {
        const emails = data.newUserEmail
          .split(",")
          .map((s) => s.trim().toLowerCase());

        if (newUserAddBackendEnabled) {
          const params: CreateUserParams = {
            userEmails: emails,
            organization,
            roleAssignments: mapFormToRoleAssignments(roleData),
            hasSSO: data.hasSSO ?? false,
            sendInvitation: data.sendInvitation ?? true,
          };
          createUserNew.mutate(params);
        } else {
          createUser(
            emails,
            mapFormToRoleAssignments(roleData),
            organization,
            data.hasSSO ?? false,
            data.sendInvitation ?? true
          );
        }
      }
    }
  };

  const userFormDefaultValues = useUserFormDefaultValues(user);
  useEffect(() => {
    reset(userFormDefaultValues);
  }, [reset, userFormDefaultValues]);

  // Disable other fields if new user email not yet set
  const disabled = user ? false : !emailFieldValue;

  const [updateUserStatus, updateUser] = useWorkerAction(
    "AdminUpdateActionState",
    (
      userId: string,
      roleAssignments: UserRoleAssignment[],
      organization: string
    ) => {
      if (userId)
        return mkSetUserRolesAction({
          organization,
          roleAssignments,
          userId,
        });
    }
  );

  const [createUserStatus, createUser] = useWorkerAction(
    "AdminUpdateActionState",
    (
      newUserEmails: string[],
      roleAssignments: UserRoleAssignment[],
      organization: string,
      hasSSO: boolean,
      sendInvitation: boolean
    ) => {
      return mkCreateUserAction({
        userEmails: newUserEmails,
        roleAssignments,
        organization,
        hasSSO,
        sendInvitation,
      });
    }
  );

  const [deleteStatus, handleConfirmDelete] = useWorkerAction(
    "AdminUpdateActionState",
    () => {
      if (user !== null) {
        return mkDeleteUserAction({
          userIds: [user.id],
        });
      }
    }
  );

  const resetAndClose = () => {
    reset();
    closeFn();
  };

  // Close the modal once status changes to Resolved
  useOnEq(updateUserStatus, "Resolved", resetAndClose);
  useOnEq(createUserStatus, "Resolved", resetAndClose);
  useOnEq(deleteStatus, "Resolved", resetAndClose);
  useOnEq<string>(createUserNew.status, "error", resetAndClose);
  useOnEq<string>(createUserNew.status, "success", resetAndClose);

  const actions = (
    <>
      {user ? (
        <ConfirmationModal
          onConfirm={handleConfirmDelete}
          buttonText={t`deleteUser.buttonText`}
          cancelText={t`deleteUser.cancelText`}
          confirmText={t`deleteUser.confirmText`}
          prompt={
            <Trans
              ns="admin"
              i18nKey="users.edit.deleteUser.prompt"
              values={{ user: user?.userData.email }}
            />
          }
          title={t`deleteUser.title`}
          buttonProps={{
            variant: "text",
            color: "danger",
            size: "large",
          }}
        />
      ) : null}
      {user && ssoInviteEnabled ? (
        <Button
          label={t`inviteToSso`}
          size="large"
          variant="primary"
          className="ml-8"
          disabled={
            !organizationInfo?.tenantManagerSSOEnabled ||
            inviteUserToSSO.isLoading ||
            inviteUserToSSO.isSuccess
          }
          icon={inviteUserToSSO.isSuccess ? IconCheck24Px : undefined}
          loading={inviteUserToSSO.isLoading}
          onClick={() => inviteUserToSSO.mutate(user.id)}
        />
      ) : null}

      <div className="ml-auto gap-4 flex">
        <Button
          label={t`cancel`}
          variant="text"
          onClick={() => {
            closeFn();
          }}
          size="large"
        />
        {user ? (
          <Button
            label={t`save`}
            onClick={handleSubmit(onSubmit)}
            loading={updateUserStatus === "Pending" || createUserNew.isLoading}
            size="large"
          />
        ) : (
          <Button
            label={t`create`}
            onClick={handleSubmit(onSubmit)}
            loading={createUserStatus === "Pending"}
            size="large"
            disabled={!methods.getValues("newUserEmail")}
          />
        )}
      </div>
    </>
  );

  return (
    <Modal
      closeModal={resetAndClose}
      isOpen={isOpen}
      width={960}
      title={
        user ? (
          <Trans
            ns="admin"
            i18nKey="users.edit.title"
            values={{ user: user?.userData.email }}
          />
        ) : (
          <Trans
            ns="admin"
            i18nKey="users.create.title"
            values={{ organization: organization }}
          />
        )
      }
      actions={actions}
    >
      <FormProvider {...methods}>
        {!user && (
          <>
            <Text text={t`emailInfo`} />
            <div className="flex">
              <Input className={"w-full"} {...mkInputProps("newUserEmail")} />
            </div>
            <Controller
              control={control}
              name={"sendInvitation"}
              render={({
                field: { value = organizationInfo?.sendInvitation, onChange },
              }) => (
                <Checkbox
                  className={"mt-3"}
                  checked={value}
                  setChecked={onChange}
                  label={t`form.sendInvitation`}
                  disabled={SSOValue}
                />
              )}
            />
          </>
        )}
        <LicenseFeatureContainer feature={LicenseFeature.USER_ROLES}>
          <div className="flex w-full">
            <MainUserRoles
              disabled={disabled}
              user={user}
              orgSSO={organizationInfo?.hasSSO ?? false}
            />
          </div>
          <Tabs
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            matchParentWidth
            tabs={tabs}
          />
        </LicenseFeatureContainer>
        {selectedTab === "costControl" && (
          <PermissionTypeContainers
            application={selectedTab}
            disabled={disabled}
            user={user}
          />
        )}
        {selectedTab === "estimation" && (
          <PermissionTypeContainers
            application={selectedTab}
            disabled={disabled}
            user={user}
          />
        )}
      </FormProvider>
    </Modal>
  );
};
