import { useRef, useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "@mescius/activereportsjs/pdfexport";
import "@mescius/activereportsjs/htmlexport";
import "@mescius/activereportsjs/tabulardataexport";
import "@mescius/activereportsjs/xlsxexport";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import "@mescius/activereportsjs/styles/ar-js-viewer.css";
import { Core } from "@mescius/activereportsjs";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Loader, ToggleVisibilityButton, IconLtV1, Button } from "@tocoman/ui";
import { Viewer } from "@mescius/activereportsjs-react";
import { StringCombobox } from "../../../components/StringCombobox";
import {
  useGetReportTemplateNames,
  useGetReportTemplateByName,
} from "./ReportHooks";
import { ReportViewProps, AllReportParametersData } from "./types";
import { getAccessToken } from "src/client-ts/auth/accessToken";
import { setParameterValues } from "./utils";
import { ParameterValueMap } from "./types";
import { reportCustomCodes } from "../../SuperAdmin/ReportDesigner/CustomFunctions";
import { ParameterForm } from "./ParameterForm";
import { getParameterValuesFromFormData } from "./utils";
import { useUserDataQuery } from "../../Projects/useUserData";

Core.CustomCode.registerFunctions(reportCustomCodes);

export type ReportSelectionForm = {
  reportName?: string;
} & AllReportParametersData;

export const Report = ({ projectId }: ReportViewProps) => {
  const { t } = useTranslation("reports");
  const methods = useForm<ReportSelectionForm>();
  const viewerRef = useRef<Viewer>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const { watch, handleSubmit } = methods;

  const activeReportName = watch("reportName");

  // Update report when user changes language
  const user = useUserDataQuery();
  const currentLanguage = user?.userData.language;

  const [activeReport, setActiveReport] = useState<ARJS.Report>({});
  const [token, setToken] = useState("");
  const {
    data: listResults,
    isLoading: reportsLoading,
  } = useGetReportTemplateNames(currentLanguage);
  const reportResults = useGetReportTemplateByName(activeReportName);
  const { data: report } = reportResults;

  const isDataLoading = reportsLoading || reportResults.isLoading;

  useEffect(() => {
    async function getToken() {
      const token = await getAccessToken();
      setToken(token);
    }
    getToken();
  }, []);

  const initialValues: ParameterValueMap = {
    projectId: [projectId.toString()],
    token: [token],
  };

  useEffect(() => {
    if (report) {
      const initialReportTemplate: ARJS.Report = {
        ...report,
        ReportParameters:
          setParameterValues(report?.ReportParameters || [], initialValues) ||
          [],
      };
      setActiveReport(initialReportTemplate);
    }
  }, [report]);

  const renderReport = () => {
    if (viewerRef.current) {
      viewerRef.current.Viewer.open(activeReport, {});
    }
  };

  useEffect(() => {
    if (activeReport) {
      renderReport();
    }
  }, [activeReport]);

  const handlePreviewSubmit = (data: AllReportParametersData) => {
    const parameterValues = getParameterValuesFromFormData(data);

    const reportWithParameterData = {
      ...activeReport,
      ReportParameters: setParameterValues(
        activeReport?.ReportParameters || [],
        parameterValues
      ),
    };
    setActiveReport(reportWithParameterData);
  };

  return (
    <div className={"flex h-full w-full"}>
      <div className={"flex flex-row w-full"}>
        <div className={"flex flex-col gap-4 pr-3 w-auto"}>
          <div className="flex flex-row items-start gap-5 min-h-full max-h-full ml-5 overflow-y-auto">
            <div ref={targetRef}>
              <div className="flex flex-col gap-5 w-[250px] m-3 h-full">
                <FormProvider {...methods}>
                  {!isDataLoading && (
                    <>
                      <Controller
                        name="reportName"
                        render={({ field: { onChange, value } }) => (
                          <StringCombobox
                            label={t("report")}
                            initialValue={value}
                            items={listResults || []}
                            onValueChange={onChange}
                          />
                        )}
                      />
                      {activeReport?.ReportParameters && (
                        <ParameterForm
                          reportParameters={activeReport.ReportParameters}
                          projectId={projectId}
                        />
                      )}
                    </>
                  )}
                  <Button
                    className="w-48 mt-3"
                    onClick={handleSubmit((d) => handlePreviewSubmit(d))}
                    label={t`setReportSettings`}
                  />
                </FormProvider>
              </div>
            </div>
            <div className="border-l border-l-light min-h-full ml-2">
              <ToggleVisibilityButton
                icon={IconLtV1}
                targetRef={targetRef}
                className="ml-[-12px] mt-3"
              />
            </div>
          </div>
        </div>
        <div className={"w-full h-full"}>
          {isDataLoading && <Loader testId={"basic-info-loader"} />}
          {!isDataLoading && report && (
            <div className={"w-full h-full"}>
              <Viewer theme="ActiveReports" ref={viewerRef} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
