import {
  Button,
  IconCheck24Px,
  IconExclamation,
  Input,
  Notification,
} from "@tocoman/ui";
import {
  Controller,
  RegisterOptions,
  useForm,
  useWatch,
} from "react-hook-form";
import { useUpdateCurrentOrganization } from "./useUpdateCurrentOrganization";
import { EditableOrganizationData } from "./OrganizationSettings";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationWithSettings } from "../../../../../server-ts/users-organizations/UsersOrganizationsRouter";
import { MultivalueStringCombobox } from "../../../../components/MultivalueStringCombobox";
import { StringCombobox } from "../../../../components/StringCombobox";
import { countryToLocale } from "../../../../utils/country";

type OrgInfoFormProps = {
  currentOrganization: OrganizationWithSettings;
};

export const OrgInfoForm = ({ currentOrganization }: OrgInfoFormProps) => {
  const { t } = useTranslation("settings");

  const [orgNotFoundError, setOrgNotFoundError] = useState(false);
  const [success, setSuccess] = useState(false);

  const allowedCountries = Object.values(countryToLocale);

  const methods = useForm<EditableOrganizationData>({
    values: {
      companyName: currentOrganization?.companyName,
      description: currentOrganization?.description,
      defaultVAT: currentOrganization?.defaultVAT ?? 25.5,
      defaultCountry:
        currentOrganization?.OrganizationSettings?.defaultCountry ?? "FIN",
      allCountries: currentOrganization?.OrganizationSettings?.allCountries ?? [
        "FIN",
      ],
    },
  });

  const handleFormChange = () => {
    setSuccess(false);
    setOrgNotFoundError(false);
  };

  const { register, handleSubmit, reset, control, watch, setValue } = methods;

  const watchName = useWatch({
    control,
    name: "companyName",
  });
  const watchDescription = useWatch({
    control,
    name: "description",
  });
  const selectedCountries = watch("allCountries");
  const defaultCountry = watch("defaultCountry");

  const mkInputProps = (
    field: keyof EditableOrganizationData,
    registerOptions?: RegisterOptions<EditableOrganizationData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  const updateCurrentOrganization = useUpdateCurrentOrganization();

  const onSave = (data: EditableOrganizationData) => {
    const companyName = data.companyName === "" ? null : data.companyName;
    const description = data.description === "" ? null : data.description;
    const allCountries =
      data.allCountries.length === 0
        ? currentOrganization.OrganizationSettings?.allCountries || ["FIN"]
        : data.allCountries;
    const defaultCountry =
      data.defaultCountry === ""
        ? currentOrganization.OrganizationSettings?.defaultCountry || "FIN"
        : data.defaultCountry;
    const saveData: EditableOrganizationData = {
      name: currentOrganization?.name,
      companyName: companyName,
      description: description,
      defaultVAT: Number(data.defaultVAT),
      defaultCountry: defaultCountry,
      allCountries: allCountries,
    };
    updateCurrentOrganization
      .mutateAsync(saveData)
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setOrgNotFoundError(true);
        }
      });
  };

  const closeError = () => {
    setOrgNotFoundError(false);
    reset();
  };

  useEffect(() => {
    handleFormChange();
  }, [watchName, watchDescription]);

  useEffect(() => {
    if (selectedCountries.length === 0) {
      setValue("defaultCountry", "");
    }
  }, [selectedCountries]);

  return (
    <>
      <form
        className="flex flex-col w-[400px] mt-5"
        onSubmit={handleSubmit(onSave)}
      >
        <div className={"flex gap-5"}>
          <div className={"w-1/2"}>
            <Input {...mkInputProps("companyName")} />

            <Input {...mkInputProps("description")} />

            <Input
              {...mkInputProps("defaultVAT")}
              type={"number"}
              step={"any"}
            />
          </div>
          <div className={"w-1/2"}>
            <Controller
              name={"allCountries"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultivalueStringCombobox
                  label={t("allCountries")}
                  items={allowedCountries}
                  onValueChange={onChange}
                  initialValue={value}
                />
              )}
            />
            <Controller
              name={"defaultCountry"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <StringCombobox
                  label={t("defaultCountry")}
                  items={selectedCountries ?? []}
                  onValueChange={onChange}
                  initialValue={value}
                  disabled={selectedCountries.length === 0}
                  disabledTooltip={t("noCountriesSelected")}
                />
              )}
            />
          </div>
        </div>
        {success && (
          <Notification
            title={t("updateSuccess")}
            description={t("orgUpdated")}
            type={"success"}
            icon={IconCheck24Px}
            className={"w-[300px] mt-5"}
            onCloseFn={() => setSuccess(false)}
          />
        )}
        {orgNotFoundError && (
          <Notification
            title={t("error")}
            description={t("orgNotFound")}
            type={"error"}
            icon={IconExclamation}
            className={"w-[300px] mt-5"}
            onCloseFn={closeError}
          />
        )}
        <Button
          className={"w-auto self-start my-5"}
          type={"submit"}
          disabled={defaultCountry === null}
        >
          {t("save")}
        </Button>
      </form>
    </>
  );
};
