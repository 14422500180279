import { MultivalueCombobox, SelectItem } from "@tocoman/ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  items: string[];
  onValueChange: (value: string[] | null) => void;
  initialValue: string[] | null;
  disabled?: boolean;
  disabledTooltip?: string;
  limit?: number;
  testId?: string;
};

export const MultivalueStringCombobox = ({
  label,
  items,
  onValueChange,
  initialValue,
  disabled = false,
  disabledTooltip,
  limit,
  testId,
}: Props) => {
  const { t } = useTranslation("component", {
    keyPrefix: "MultivalueComboboxes",
  });

  const [selectedItems, setSelectedItems] = useState<SelectItem<string>[]>([]);

  const [filterString, setFilterString] = useState<string>("");

  const filteredItems = useMemo(() => {
    return items
      ?.filter((item) =>
        item.toLowerCase().includes(filterString.toLowerCase())
      )
      .map((item) => ({
        key: item,
        label: item,
        value: item,
      }));
  }, [items, filterString]);

  const onChange = useCallback(
    (newValue: SelectItem<string>[] | null) => {
      setSelectedItems(newValue || []);
      onValueChange(newValue?.map((item) => item.value) || null);
    },
    [selectedItems, onValueChange]
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedItems(
        initialValue.map((item) => ({
          key: item,
          label: item,
          value: item,
        }))
      );
    }
  }, [initialValue]);

  return (
    <MultivalueCombobox
      testId={testId}
      selectedItems={selectedItems}
      items={filteredItems}
      onValueChange={onChange}
      filterHandler={setFilterString}
      label={label}
      placeholder={label}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      multipleValuesText={t("multipleValues")}
      selectAllText={t("selectAll")}
      allSelectedText={t("all")}
      limit={limit}
      disableSelectAll={limit !== undefined}
    />
  );
};
