import { TextareaAutosize } from "@mui/material";
import { Button, Checkbox, Input, Modal } from "@tocoman/ui";
import { ChangeEvent, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useCreateReportTemplate,
  useGetLicensePlans,
} from "../hooks/useReportTemplates";
import { StringCombobox } from "../../../../components/StringCombobox";
import { countryToLocale } from "../../../../utils/country";
import { languageToLocale } from "../../../../utils/language";
import { MultivalueStringCombobox } from "../../../../components/MultivalueStringCombobox";
import { useGetOrganizations } from "../../Tabs/useSuperAdminOrganizations";

type CreateReportTemplateFormProps = {
  name: string;
  template: string;
  country?: string;
  language?: string;
  plan?: string[];
  organization?: string[];
};

export default function CreateReportTemplateDialog() {
  const { t } = useTranslation("superAdmin", {
    keyPrefix: "reportDesigner.createTemplateDialog",
  });

  const methods = useForm<CreateReportTemplateFormProps>({
    defaultValues: {
      name: "",
      template: JSON.stringify({
        Name: "Report",
        Body: {
          ReportItems: [
            {
              Type: "textbox",
              Name: "TextBox1",
              Value: "Hello, ActiveReportsJS Designer",
              Style: {
                FontSize: "18pt",
              },
              Width: "8.5in",
              Height: "0.5in",
            },
          ],
        },
      }),
    },
  });

  const [open, setOpen] = useState(false);
  const plans = useGetLicensePlans().data;
  const [checkedplans, setCheckedPlans] = useState<string[]>([]);

  const countries = Object.values(countryToLocale);
  const languages = Object.values(languageToLocale);

  const updateReportTemplates = useCreateReportTemplate();

  function getOrganizationStringArray(): string[] {
    const organizationsArray = useGetOrganizations();
    const filteredOrganizations: string[] = [];
    if (organizationsArray)
      organizationsArray.forEach((org) => filteredOrganizations.push(org.name));
    return filteredOrganizations;
  }

  const handleSubmit = async (data: CreateReportTemplateFormProps) => {
    const templateName = data.name;
    const templateAsJson = JSON.parse(data.template);

    updateReportTemplates.mutate({
      name: templateName,
      template: templateAsJson,
      country: data.country,
      language: data.language,
      organizations: data.organization,
      plans: checkedplans,
    });

    methods.reset();
    setOpen(false);
  };

  // Handle checkbox change
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCheckedPlans((prevCheckedValues) =>
      event.target.checked
        ? [...prevCheckedValues, value]
        : prevCheckedValues.filter((item) => item !== value)
    );
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t("create")}</Button>
      <Modal
        isOpen={open}
        closeModal={() => setOpen(false)}
        title={t("create")}
        width={700}
        actions={
          <>
            <Button
              variant={"text"}
              onClick={() => setOpen(false) > setOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button type="submit" form="create-report-template-form">
              {t("save")}
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <form
            id="create-report-template-form"
            onSubmit={methods.handleSubmit(handleSubmit)}
            className="flex flex-col gap-2 w-full"
          >
            <>
              <div className="flex flex-col p-2">
                <div className="p-2">
                  <Input
                    label={t("name")}
                    {...methods.register("name")}
                    required
                  />
                </div>
                <div className="p-2">
                  <label htmlFor="template">{t("template")}</label>
                  <TextareaAutosize
                    id="template"
                    {...methods.register("template")}
                    className="w-full border-light border rounded-md p-2"
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="basis-1/3 p-2">
                  <Controller
                    name="country"
                    render={({ field: { onChange, value } }) => (
                      <StringCombobox
                        label={t`country`}
                        initialValue={value}
                        items={countries}
                        onValueChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name="language"
                    render={({ field: { onChange, value } }) => (
                      <StringCombobox
                        label={t`language`}
                        initialValue={value}
                        items={languages}
                        onValueChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="basis-2/3 p-2">
                  <label>{t("plan")}</label>
                  {plans &&
                    plans.length > 0 &&
                    plans.map((plan, index) => (
                      <div key={"plansCheckboxController-" + index}>
                        <Checkbox
                          label={plan.code}
                          value={plan.code}
                          checked={checkedplans.includes(plan.code)}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    ))}
                </div>
                <div className="basis-3/3 p-2">
                  <Controller
                    name="organization"
                    render={({ field: { onChange, value } }) => (
                      <MultivalueStringCombobox
                        label={t("organization")}
                        initialValue={value}
                        items={getOrganizationStringArray()}
                        onValueChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
