import { ReportTemplate } from "@prisma/client";
import { useQuery, UseQueryResult } from "react-query";
import { useTranslation } from "react-i18next";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";

const baseUrl = "/report-templates";

export function useGetReportTemplateNames(
  language?: string
): UseQueryResult<string[]> {
  const { t } = useTranslation("reports");
  return useQuery({
    queryKey: [baseUrl, language],
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate[]>(
        `${baseUrl}/valid-templates`
      );
      return data.map(({ name }) => name);
    },
    onError: (error) =>
      reportError(t("error.reportListTemplateFetch"), error as Error),
  });
}

export function useGetReportTemplateByName(
  name: string | undefined
): UseQueryResult<ARJS.Report> {
  const { t } = useTranslation("reports");
  return useQuery({
    queryKey: [baseUrl, name],
    enabled: !!name,
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate>(
        `${baseUrl}/${name}/template`
      );
      return data;
    },
    onError: (error) => {
      if (name) {
        reportError(t("error.reportTemplateFetch"), error as Error);
      }
    },
  });
}
